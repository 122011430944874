import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/spark-logo.png";

import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const NavbarHeader = ({ isLoggedIn }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const location = useLocation();

  const navigation = [
    { name: "Home", to: "/" },
    { name: "About", to: "/about" },
    { name: "0 to 1", to: "/0to1" },
    { name: "Northstar", to: "/northstar" },
  ];

  const isActiveRoute = (path) => location.pathname === path;

  return (
    <header className="bg-white">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-screen-2xl items-center justify-between p-6 lg:px-8"
      >
        <div className="flex items-center gap-x-12">
          <Link to="/" className="-m-1.5 p-1.5">
            <img alt={"Spark"} src={Logo} className="h-16 w-auto" />
          </Link>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.to}
                className={`text-base !font-bold ${isActiveRoute(item.to) ? "text-spark-700" : "text-black"
                  }`}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-6">
          {!isLoggedIn ? (
            <>
              <Link
                to="/login"
                className="text-sm rounded-md font-lexendRegular text-spark-700 border border-slate-200 py-2 px-5"
              >
                Login
              </Link>

              <Link
                to="/register"
                className="dark:text-white font-lexendRegular bg-spark-700 text-sm rounded-md text-white border border-white py-2 px-3"
              >
                Signup
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/dashboard"
                className="dark:text-white font-lexendRegular bg-spark-700 text-lg hover:bg-spark-500 rounded-3xl text-white py-2 px-10"
              >
                Go to Dasboard
              </Link>
            </>
          )}
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        {mobileMenuOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-10" />
        )}
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full rounded-tl-[60px] rounded-bl-[60px] overflow-y-auto bg-white px-6 py-6 sm:max-w-xs sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between pb-4">
            <Link to="/" className="-m-1.5 p-1.5">
              <img alt={"Spark"} src={Logo} className="h-20 w-auto" />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <hr className="lg:hidden" />
          <div className="flow-root">
            <div className="space-y-2 py-6">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.to}
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base !font-bold ${isActiveRoute(item.to) ? "text-spark-700" : "text-black"
                    } text-gray-900 hover:bg-gray-50`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div>
              {!isLoggedIn ? (
                <>
                  <div className="">
                    <Link
                      to="/login"
                      className="text-sm block font-lexendRegular w-full rounded-md text-spark-700 border border-slate-200 py-2 px-5"
                    >
                      Login
                    </Link>
                  </div>

                  <div className="mt-3">
                    <Link
                      to="/register"
                      className="dark:text-white font-lexendRegular block w-full bg-spark-700 text-sm rounded-md text-white border border-white py-2 px-3"
                    >
                      Signup
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <Link
                    to="/dashboard"
                    className="dark:text-white font-lexendRegular block w-full bg-spark-700 text-lg hover:bg-spark-500 rounded-3xl text-white py-2 px-10"
                  >
                    Go to Dasboard
                  </Link>
                </>
              )}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default NavbarHeader;
