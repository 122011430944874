import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";
import { FaArrowRightLong } from "react-icons/fa6";
import Logo from "../../assets/images/spark-logo.png";

const Email = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  const verifyEmail = async () => {
    const response = await api.get(`users/verifyEmail?token=${token}`);
    if (response && response.status === 200) {
      navigate("/login");
      notificationSvc.success("Email has been verified");
    }
  };

  return (
    <div className="h-screen flex items-center justify-center p-4 bg-slate-200">
      <div className="flex flex-col md:flex-row shadow-custom bg-slate-900 rounded-3xl max-w-6xl w-full min-h-full md:h-auto p-4">
        {/* Left Section */}
        <div className="flex items-end justify-center md:justify-start bg-slate-200 w-full md:w-1/2 p-6 md:p-10 rounded-3xl">
          <div className="text-center md:text-left">
            <div className="mb-6">
              <img
                src={Logo}
                alt="Logo"
                className="mx-auto md:mx-0 w-28 sm:w-36 md:w-44"
              />
            </div>
            <div className="mb-8 md:mb-16">
              <h2 className="text-slate-900 text-3xl sm:text-4xl md:text-5xl font-lexendBold mb-2">
                Verify Email!
              </h2>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <section className="w-full md:w-1/2 text-white p-6 md:p-10 flex items-center justify-center min-h-full">
          <div className="w-full max-w-md">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-lexendBold mb-6 text-center">
              Click Here to Verify Email
            </h2>
            <button
              type="submit"
              onClick={verifyEmail}
              className="w-full py-3 mt-6 flex items-center justify-center bg-slate-200 text-slate-900 rounded-md font-lexendRegular transition hover:bg-slate-300"
            >
              Verify Email&nbsp;&nbsp;
              <FaArrowRightLong />
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Email;
