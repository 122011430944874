import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import authSvc from "../../Services/auth-service";
import { FaArrowRightLong } from "react-icons/fa6";

const Login = ({ onLogin, isLoggedIn }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();
    const { isLoggedIn, profileCreated } = await authSvc.login(
      formData.email,
      formData.password
    );

    if (isLoggedIn) {
      onLogin();

      if (profileCreated) {
        navigate("/");
        navigate("/");
      } else {
        navigate("/profile-setup");
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard");
  }, [isLoggedIn, navigate]);

  return (
    <>
      <h2 className="text-2xl md:text-3xl text-slate-200 lg:text-4xl !font-lexendBold mb-6 text-center md:text-left">
        Login into your account
      </h2>
      <p className="max-w-2xl text-xl text-slate-200 !font-lexendRegular">
        Login into your account and join Spark, the Internet's premier startup
        community. Supercharge your business journey!
      </p>
      <form
        className="mt-12"
        onSubmit={(e) => {
          login(e);
        }}
      >
        <div className="mb-4">
          <label className="block text-sm md:text-base text-slate-200 !font-lexendRegular mb-3">
            Email Address
          </label>
          <input
            type="email"
            id="email"
            className="w-full p-2 bg-white rounded-md text-black focus:outline-none focus:ring-2 focus:ring-spark-700"
            value={formData.email}
            onChange={(e) => {
              setFormData({
                ...formData,
                email: e.target.value,
              });
            }}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm md:text-base text-slate-200 !font-lexendRegular mb-3">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="w-full p-2 bg-white rounded-md text-black focus:outline-none focus:ring-2 focus:ring-spark-700"
            value={formData.password}
            onChange={(e) => {
              setFormData({
                ...formData,
                password: e.target.value,
              });
            }}
          />
        </div>

        <button
          type="submit"
          className="w-full py-3 mt-16 flex items-center justify-center bg-slate-200 text-slate-900 rounded-md !font-lexendRegular"
        >
          Login to your Account&nbsp;&nbsp;
          <FaArrowRightLong />
        </button>
      </form>
      <Link
        to={"/forgot-password"}
        className="!font-lexendRegular text-slate-200 block text-center mt-10"
      >
        I forgot my password
      </Link>
    </>
  );
};

export default Login;
