import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";
import { FaArrowRightLong } from "react-icons/fa6";
import Logo from "../../assets/images/spark-logo.png";

const ForgotPassword = ({ isLoggedIn }) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("users/forgot-password", { email });
      if (response && (response.status === 200 || response.status === 201)) {
        notificationSvc.success("Password reset link sent to your email.");
        navigate("/login");
      }
    } catch (error) {
      notificationSvc.error(
        "Failed to send password reset email. Please try again."
      );
    }
  };

  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard");
  }, [isLoggedIn, navigate]);

  return (
    <div className="h-full min-h-screen flex items-center justify-center p-6 md:p-12 bg-slate-200">
      <div className="flex flex-col md:flex-row max-w-6xl w-full h-full bg-slate-900 rounded-3xl shadow-custom p-4 md:p-2">
        {/* Left Section */}
        <div className="flex items-end justify-center md:justify-start rounded-3xl bg-slate-200 w-full md:w-1/2 p-6 md:p-10">
          <div className="text-center md:text-left">
            <div className="mb-6">
              <img src={Logo} alt="Logo" className="mx-auto md:mx-0" />
            </div>
            <div className="mb-14">
              <h2 className="text-slate-900 text-4xl md:text-5xl lg:text-6xl !font-lexendBold mb-2">
                Forgot Password?
              </h2>
              <p className="text-slate-900 text-xl md:text-2xl lg:text-3xl !font-lexendRegular">
                Recover your password in a few steps
              </p>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <section className="w-full md:w-1/2 text-white p-6 md:p-10 flex flex-col justify-center items-center md:items-start">
          <div className="w-full">
            <h2 className="text-2xl md:text-3xl lg:text-4xl !font-lexendBold mb-6 text-center md:text-left">
              Enter Your email below:
            </h2>

            <form className="mt-12 w-full" onSubmit={handleForgotPassword}>
              <div className="mb-4">
                <input
                  type="text"
                  className="w-full p-2 bg-white rounded-md text-black focus:outline-none focus:ring-2 focus:ring-spark-700"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <button
                type="submit"
                className="w-full py-3 mt-8 flex items-center justify-center bg-slate-200 text-slate-900 rounded-md !font-lexendRegular"
              >
                Send Reset Link&nbsp;&nbsp;
                <FaArrowRightLong />
              </button>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ForgotPassword;
