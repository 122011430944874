import React, { useEffect, useRef } from "react";
import { isMobile, isTablet } from "react-device-detect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CheckList = ({ step }) => {

  const steps = [
    {
      title: "Create Account",
      description: "Your account has been created and validated",
    },
    {
      title: "Personal Information",
      description: "Tell us more about you",
    },
    {
      title: "Profile Image",
      description: "Upload your picture",
    },
    {
      title: "Your Role",
      description: "What do you bring?",
    },
    {
      title: "Looking For",
      description: "What do you need?",
    },
    {
      title: "Your Skills",
      description: "What are you best at?",
    },
    {
      title: "Interests",
      description: "Which industries are you interested in?",
    },
  ];


  const isMobileOrTablet = isMobile || isTablet;
  const activeStepRef = useRef(null);

  useEffect(() => {
    if (isMobileOrTablet && activeStepRef.current) {
      activeStepRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [step, isMobileOrTablet]);

  return (
    <div className="w-full">
      <ul
        className={`${isMobileOrTablet
            ? "flex justify-between overflow-x-auto space-x-6 invisible-scrollbar"
            : "mt-4"
          }`}
      >
        {steps.map((item, index) => {
          const isCompleted = index < step - 1;
          const isActive = index === step - 1;

          return (
            <li
              key={index}
              className={`flex ${isMobileOrTablet ? "flex-col items-center" : "w-full gap-4"
                } relative ${!isMobileOrTablet && "mb-12"}`}
              ref={isActive ? activeStepRef : null}
            >
              {/* Step Icon */}
              <div
                className={`border ${isCompleted ? "border-spark-700" : "border-slate-200/20"
                  } p-1 rounded-full`}
              >
                <span
                  className={`w-10 h-16 flex items-center justify-center rounded-full ${isCompleted
                      ? "bg-spark-700"
                      : isActive
                        ? "bg-spark-700"
                        : "bg-slate-200/20"
                    } relative`}
                >
                  {isCompleted ? (
                    <svg
                      width="18"
                      height="12"
                      viewBox="0 0 18 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 6.9L4.143 10.5L12 1.5"
                        stroke="#E8EBF2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 1.563L8.429 10.563L8 10"
                        stroke="#E8EBF2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="18"
                      height="11"
                      viewBox="0 0 18 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 6.4L4.143 10L12 1"
                        stroke="#0F172A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 1.06299L8.429 10.063L8 9.49999"
                        stroke="#0F172A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}

                  {index !== steps.length - 1 && !isMobileOrTablet && (
                    <span
                      className={`absolute ${isMobileOrTablet
                          ? "left-full top-1/2 -translate-y-1/2"
                          : "top-full left-1/2 -translate-x-1/2"
                        } w-1.5 rounded-lg tail ${isCompleted ? "bg-slate-200" : "bg-slate-200/20"
                        } ${isMobileOrTablet ? "h-1.5 w-8" : "h-8 mt-3"}`}
                    ></span>
                  )}
                </span>
              </div>

              {/* Step Content */}
              {!isMobileOrTablet && (
                <div className="w-full">
                  <h5
                    className={`mb-1 !font-lexendBold text-lg text-slate-200`}
                  >
                    {item.title}
                  </h5>
                  <p className={`text-base !font-lexendLight text-slate-200`}>
                    {item.description}
                  </p>
                </div>
              )}
              {isMobileOrTablet && (
                <h5 className={`mt-2 text-center text-sm text-slate-200`}>
                  {item.title}
                </h5>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CheckList;
