import React from "react";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";

const DeleteProfile = () => {
  const deleteUser = async () => {
    const response = await api.delete("users/delete");

    if (response && response.status === 200) {
      localStorage.clear();
      window.location.reload();
      notificationSvc.success(response.data.message);
    }
  };

  return (
    <div className="p-4 sm:p-8 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-sm p-6 sm:p-8">
        <p className="font-lexendBold text-center text-lg sm:text-xl">
          Are you sure you want to delete the User?
        </p>
        <div className="mt-6 flex justify-center">
          <button
            onClick={() => {
              window.confirm("Are you sure you want to delete?") &&
                deleteUser();
            }}
            type="button"
            className="px-8 sm:px-12 py-3 bg-spark-700 text-white rounded-lg text-sm sm:text-base font-lexendBold shadow-sm hover:bg-spark-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spark-600"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteProfile;
