import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../Services/api-service';

export const fetchEvents = createAsyncThunk('events/fetchEvents', async (queryParams, thunkAPI) => {
    try {
        const response = await api.get(`events/list`);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

export const fetchMyEvents = createAsyncThunk('events/fetchMyEvents', async (user, thunkAPI) => {
    if (!user) return thunkAPI.rejectWithValue('No match provided');

    try {
        const response = await api.get(`events/user/${user.userId}`);

        return response.data ?? {};
    } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
});

const eventsSlice = createSlice({
    initialState: {
        events: {},
        myEvents: {},
        loading: false,
        error: null,
    },
    name: 'events',
    reducers: {
        resetEvents: (state) => {
            state.events = {};
            state.myEvents = [];
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEvents.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.events = action.payload;
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchMyEvents.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMyEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.myEvents = action.payload;
            })
            .addCase(fetchMyEvents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default eventsSlice.reducer;
export const { resetEvents } = eventsSlice.actions;
