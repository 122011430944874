import { ArrowLeft01Icon, ArrowRight01Icon } from "hugeicons-react";
import React from "react";

const Pagination = ({ perPage, total, activePage, onPageChange, containerClass }) => {
  const totalPages = Math.ceil(total / perPage);
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div className={`flex items-center justify-center space-x-2 ${containerClass}`}>
      {/* Previous Button */}
      <button
        onClick={() => handlePageChange(activePage - 1)}
        className={`px-2 py-1 text-xs flex items-center ${
          activePage === 1 ? "text-gray-400 cursor-not-allowed" : "text-gray-800"
        }`}
        disabled={activePage === 1}
      >
        <ArrowLeft01Icon /> Prev
      </button>

      {/* Page Numbers */}
      {pages.map((page) => (
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={`w-8 h-8 flex items-center justify-center rounded-full text-sm ${
            activePage === page
              ? "bg-spark-primary text-white"
              : "text-balck hover:bg-gray-200"
          }`}
        >
          {page}
        </button>
      ))}

      {/* Next Button */}
      <button
        onClick={() => handlePageChange(activePage + 1)}
        className={`px-2 py-1 text-xs flex items-center ${
          activePage === totalPages
            ? "text-gray-400 cursor-not-allowed"
            : "text-black0"
        }`}
        disabled={activePage === totalPages}
      >
        Next <ArrowRight01Icon />
      </button>
    </div>
  );
};

export default Pagination;
