import React, { useState } from "react";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const changePass = async (e) => {
    e.preventDefault();
    const response = await api.patch("users/password", formData);

    if (response && response.status === 200) {
      notificationSvc.success("Password Changed Successfully.");
    }
  };

  return (
    <div className="px-4 sm:px-6 md:px-14 py-14 max-w-4xl">
      <h1 className="font-lexendBold text-2xl sm:text-3xl md:text-4xl">
        Change Password
      </h1>
      <p className="font-lexendRegular text-sm sm:text-base md:text-xl mt-1">
        Create a new password that is at least 8 characters long.
      </p>

      <form className="mt-14" onSubmit={(e) => changePass(e)}>
        {/* Current Password */}
        <div className="mt-3">
          <label
            htmlFor="current-password"
            className="block font-lexendRegular text-slate-900"
          >
            Current Password
          </label>
          <div className="mt-2">
            <input
              id="current-password"
              type="password"
              className="block w-full max-w-md border-gray-300 rounded-md py-1.5 px-3 text-slate-900"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  oldPassword: e.target.value,
                }));
              }}
            />
          </div>
        </div>

        {/* New Password */}
        <div className="mt-8">
          <label
            htmlFor="new-password"
            className="block font-lexendRegular text-slate-900"
          >
            New Password
          </label>
          <div className="mt-2">
            <input
              id="new-password"
              type="text"
              className="block w-full max-w-md border-gray-300 rounded-md py-1.5 px-3 text-slate-900"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  newPassword: e.target.value,
                }));
              }}
            />
          </div>
        </div>

        {/* Repeat Password */}
        <div className="mt-8">
          <label
            htmlFor="repeat-password"
            className="block font-lexendRegular text-slate-900"
          >
            Repeat Password
          </label>
          <div className="mt-2">
            <input
              id="repeat-password"
              type="text"
              className="block w-full max-w-md border-gray-300 rounded-md py-1.5 px-3 text-slate-900"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  confirmNewPassword: e.target.value,
                }));
              }}
            />
          </div>
        </div>

        <button
          type="submit"
          className="mt-10 bg-slate-200 w-full max-w-md py-3 rounded-lg font-lexendRegular"
        >
          Change Password
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
