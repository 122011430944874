import React, { useEffect, useState } from "react";
import Check from "../../assets/images/check-mark.svg";
import { api } from "../../Services/api-service";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";
import { STRIPE_PUBLIC_KEY } from "../../utils/constans";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivePlan } from "../../redux/activePlanSlice";

const Membership = () => {
  const [plans, setPlans] = useState([]);
  
  const [loading, setLoading] = useState(true);

  const hostName = window.location.origin;

  const checkOutStatus = {
    successUrl: `${hostName}/checkout-success`,
    failureUrl: `${hostName}/checkout-failure`,
    conflictUrl: `${hostName}/checkout-conflict`,
  };

  const dispatch = useDispatch();

  const activePlan = useSelector((state) => state.activePlan.activeplan);

  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    dispatch(fetchActivePlan());
  }, [dispatch]);

  // const getPlans = async () => {
  //   const response = await api.get("plans");
  //   if (response && response.status === 200) {
  //     setPlans(response.data);
  //   }
  // };

  const getPlans = async () => {
    try {
      const response = await api.get("plans");
      if (response.status === 200) {
        setPlans(response.data);
      }
    } catch (err) {
      console.log(err || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const getActivePlanDetails = () => {
    if (activePlan === null) {
      return { planName: "Free", planId: null };
    } else {
      const activatedPlan = plans.find((plan) => {
        return plan.planId === activePlan.planId;
      });
      return activatedPlan;
    }
  };

  const currentPlan =
    getActivePlanDetails()?.planId === null
      ? plans.find((plan) => plan.price === 0)
      : plans.find((plan) => plan.planId === activePlan.planId);

  const handlePayment = async (e, id) => {
    e.preventDefault();

    const stripePromise = await loadStripe(STRIPE_PUBLIC_KEY);

    const response = await api.post(
      `plans/${id}/create-checkout`,
      checkOutStatus
    );

    const session = await response.data?.id;
    stripePromise.redirectToCheckout({
      sessionId: session,
    });
  };

  if (loading) {
    return <p>Loading plan...</p>;
  }

  return (
    <div className="p-4 sm:p-14">
      <h1 className="font-lexendBold text-2xl sm:text-4xl">Membership</h1>
      <div className="mt-6 sm:mt-10 px-4 sm:px-6 max-w-3xl">
        <h1 className="font-lexendLight text-xl sm:text-3xl text-center sm:text-left">
          {currentPlan?.planName}
        </h1>
        <p className="font-lexendRegular text-sm sm:text-base text-center sm:text-left">
          {currentPlan?.description}
        </p>
        <div className="mt-6 sm:mt-10 text-center sm:text-left">
          <h1 className="font-lexendBold text-3xl sm:text-5xl">
            ${currentPlan?.price}
            <span className="font-lexendRegular text-base sm:text-xl">
              / {currentPlan?.billingCycle}
            </span>
          </h1>
        </div>
        <div className="mt-6 sm:mt-10">
          {currentPlan?.Features.map((spec, index) => (
            <div key={index} className="mt-5 flex items-start sm:items-center">
              <img
                src={Check}
                alt="Checkmark"
                className="mr-4 sm:mr-7 w-6 sm:w-8"
              />
              <p className="text-sm sm:text-base">{spec.featureName}</p>
            </div>
          ))}
        </div>
        <div className="mt-10 sm:mt-14 flex flex-col sm:flex-row gap-4 sm:gap-6 justify-center sm:justify-start">
          <button
            onClick={(e) => {
              handlePayment(e, currentPlan.planId);
            }}
            className="bg-slate-200 font-lexendRegular px-10 sm:px-24 py-3 rounded-lg text-sm sm:text-base"
          >
            Cancel your plan
          </button>
          <Link
            to={"/subscriptions"}
            className="bg-spark-700 px-10 sm:px-24 text-center py-3 font-lexendRegular text-white rounded-lg text-sm sm:text-base"
          >
            Change your plan
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Membership;
