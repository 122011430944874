import { CancelCircleIcon } from 'hugeicons-react';
import React from 'react';
import Modal from 'react-responsive-modal';
import Ticket from './Ticket';

const Tickets = ({modalIsOpen, closeModal, eventData}) => {
    return (
        <Modal
            classNames={{
                root: '!z-[1005]',
                overlay: 'backdrop-blur-sm',
                modalContainer: 'flex justify-center items-center',
                modal: '!m-0 rounded-xl sm:w-9/12 w-full !max-w-none h-fit max-h-screen',
                closeButton: '!top-[20px]',
            }}
            open={modalIsOpen}
            onClose={closeModal}
            contentLabel='Event Tickets'
            styles={{ zIndex: '1003' }}
            closeIcon={<CancelCircleIcon />}
        >
            <div className='grid grid-cols-[repeat(auto-fit,minmax(340px,auto))] gap-4 justify-center'>
                {
                    eventData?.tickets?.map((ticket, i) => <Ticket key={i} ticket={ticket} eventData={eventData} />)
                }
            </div>
        </Modal>
    );
};

export default Tickets;
