import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import authSvc from "../../Services/auth-service";

import spark_thunder from "../../assets/icons/spark_thunder.png";
import {
  MenuSquareIcon,
  BubbleChatIcon,
  UserSettings01Icon,
  CalendarFavorite01Icon,
  // NotificationBlock01Icon,
  FileSecurityIcon,
  InformationCircleIcon,
  Logout03Icon,
  Cancel01Icon,
} from "hugeicons-react";
import NavItems from "./NavItems";
import Subscriptions from "../icons/Subscriptions";

const Sidebar = ({ isOpen, setSideBarOpen }) => {
  const navItemsArr = [
    {
      to: "/",
      label: "Dashboard",
      icon: MenuSquareIcon,
      activePath: "/",
      isActive: false,
    },
    {
      to: "/chat",
      label: "Messages",
      icon: BubbleChatIcon,
      activePath: "chat",
      isActive: false,
    },
    {
      to: "/profile",
      label: "Profile",
      icon: UserSettings01Icon,
      activePath: "profile",
      isActive: false,
    },
    {
      to: "/events",
      label: "Events",
      icon: CalendarFavorite01Icon,
      activePath: "events",
      isActive: false,
    },
    // {
    //   to: "/notifications",
    //   label: "Notifications",
    //   icon: NotificationBlock01Icon,
    //   activePath: "notification",
    //   isActive: false,
    // },
    {
      to: "/subscriptions",
      label: "Subscriptions",
      icon: Subscriptions,
      activePath: "subscription",
      isActive: false,
    },
  ];

  const [isHovered, setIsHovered] = useState(false);
  const [navItems, setNavItems] = useState(navItemsArr);

  const location = useLocation();

  useEffect(() => {
    const activeNav = location.pathname;

    let newState = [...navItems];
    newState = newState.map((val) => {
      return {
        ...val,
        isActive:
          val.activePath === "/"
            ? activeNav === val.activePath
            : activeNav.includes(val.activePath),
      };
    });

    setNavItems(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const updateNavItem = (index) => {
    let newState = [...navItems];
    newState = newState.map((val, i) => {
      return {
        ...val,
        active: i === index,
      };
    });
    setNavItems(newState);
  };

  return (
    <>
      {isOpen && (
        <div
          className="bg-black opacity-40 fixed z-[1001] top-0 bottom-0 left-0 right-0 md:hidden"
          onClick={() => setSideBarOpen(false)}
        ></div>
      )}
      <aside
        className={`z-[1002] fixed md:sticky top-0 bottom-0 left-0 h-screen rounded-e-lg overflow-x-clip ${isHovered ? "md:w-fit" : "md:w-16"
          } bg-white transition-all duration-300 ${!isOpen ? "w-0" : ""}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Cancel01Icon
          className="md:hidden absolute right-2 top-2"
          onClick={() => setSideBarOpen(false)}
        />

        <div className={`flex flex-col h-full py-4`}>
          <div className={`flex p-5 h-12 items-center justify-start`}>
            <div className="bg-spark-primary w-8 h-8 flex items-center justify-center rounded-md min-w-8">
              <img width={12} src={spark_thunder} alt="spark" />
            </div>
          </div>
          <div className="mt-4 flex-grow">
            <ul className={`space-y-2 items-stretch flex flex-col items-start`}>
              {navItems.map(({ to, label, icon, isActive }, i) => (
                <NavItems
                  key={i}
                  keyNav={i}
                  to={to}
                  label={label}
                  icon={icon}
                  isActive={isActive}
                  isHovered={isHovered}
                  clickEvent={updateNavItem}
                  setSideBarOpen={setSideBarOpen}
                />
              ))}
              <Link
                to="https://spark-founders.c.paperos.com/c/0/workbench/2/205/0/new"
                className="flex items-center p-2 px-6 hover:bg-gray-200 rounded-md"
              >
                <FileSecurityIcon className="w-6 h-6 text-gray-600 min-w-6" />
                <span
                  className={`text-gray-700 truncate transition-width duration-300 w-auto ms-7 ${isHovered ? "md:ms-7" : "md:w-0 md:ms-0"
                    }`}
                >
                  Data Room
                </span>
              </Link>
            </ul>
          </div>
          <div
            className={`space-y-2 items-stretch mt-4 flex flex-col items-start`}
          >
            <Link
              to="/help"
              className="flex items-center p-2 px-6 hover:bg-gray-200 rounded-md"
            >
              <InformationCircleIcon className="w-6 h-6 text-gray-600 min-w-6" />
              <span
                className={`text-gray-700 truncate transition-width duration-300 w-auto ms-7 ${isHovered ? "md:ms-7" : "md:w-0 md:ms-0"
                  }`}
              >
                Help
              </span>
            </Link>
            <button
              onClick={authSvc.logout}
              className="flex items-center p-2 px-6 hover:bg-gray-200 rounded-md"
            >
              <Logout03Icon className="w-6 h-6 text-gray-600 min-w-6" />
              <span
                className={`text-gray-700 truncate transition-width duration-300 w-auto ms-7 ${isHovered ? "md:ms-7" : "md:w-0 md:ms-0"
                  }`}
              >
                Log out
              </span>
            </button>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
