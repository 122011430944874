import React from "react";
import { Link } from "react-router-dom";

const EventMatchesMessage = () => {
  return (
    <div className="w-full h-full">
      <h1 className="text-center !font-lexendBold text-5xl">
        Great Connections take <br />
        time!
      </h1>
      <p className="mt-8 text-center font-lexendMedium text-2xl">
        Matches will open as the live match event <br /> begins.{" "}
      </p>
      <p className="mt-8 text-center font-lexendMedium text-2xl">
        Stay tuned and we'll help you find your <br /> perfect Co-Founder. We
        are excite to see <br /> you soon!
      </p>
      <Link
        to="/events"
        className="text-spark-700 mt-10 text-center flex justify-center font-lexendRegular text-xl"
      >
        Back to Events
      </Link>
    </div>
  );
};

export default EventMatchesMessage;
