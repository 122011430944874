import React from "react";
import { Link } from "react-router-dom";

const NavItems = ({
  keyNav,
  to,
  isActive,
  icon: Icon,
  label,
  isHovered,
  clickEvent,
  setSideBarOpen,
}) => {
  return (
    <li key={to} className="relative">
      {isActive && (
        <div className="rounded-e-md bg-spark-primary absolute left-0 h-10 w-1.5"></div>
      )}
      <Link
        to={to}
        className="flex items-center p-2 px-6 hover:bg-gray-200 rounded-md w-full"
        onClick={() => {
          clickEvent(keyNav);
          setSideBarOpen(false); 
        }}
      >
        <Icon className="w-6 min-w-6 h-6 text-gray-600" />
        <span
          className={`text-gray-700 truncate transition-width duration-300 w-auto ms-7 ${
            isHovered ? "md:ms-7" : "md:w-0 md:ms-0"
          }`}
        >
          {label}
        </span>
      </Link>
    </li>
  );
};

export default NavItems;
