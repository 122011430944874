import {
    AiBrain04Icon,
    Award05Icon,
    Brain02Icon,
    CancelCircleIcon,
    City01Icon,
    Message02Icon,
    UserFullViewIcon,
} from "hugeicons-react";
import React, { useEffect, useState } from "react";
import EventItem from "../Events/EventItem";

import sidermenu from "../../assets/images/SiderMenu.png";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";

const MatchedUserProfile = React.forwardRef(
    ({ match, className, onMatchedProfileClose, onMessageButtonClick }, ref) => {
        const [events, setEvents] = useState(null);


        // eslint-disable-next-line react-hooks/exhaustive-deps
        const handleScrollLock = () => {
            const isMobileOrTablet = window.innerWidth <= 1024;
            if (match && isMobileOrTablet) {
                document.body.classList.add("modal-open");
            } else {
                document.body.classList.remove("modal-open");
            }
        };

        useEffect(() => {
            handleScrollLock();

            window.addEventListener("resize", handleScrollLock);

            return () => {
                document.body.classList.remove("modal-open");
                window.removeEventListener("resize", handleScrollLock);
            };
        }, [handleScrollLock, match]);

        useEffect(() => {
            loadEvents(match);
        }, [match]);

        const loadEvents = async (match) => {
            if (!match) return;
            const response = await api.get("events/user/" + match.user.userId);

            if (response?.data?.message) notificationSvc.info(response.data.message);
            else if (response?.data?.upcomingEvents) {
                setEvents(response.data?.upcomingEvents);
            }
        };

        return (
            <div
                ref={ref}
                className={`bg-white rounded-3xl shadow-lg p-6 ${className}`}
            >
                {/* Profile Header */}
                <div className="flex w-full justify-end p-3">
                    <CancelCircleIcon
                        className="cursor-pointer"
                        onClick={onMatchedProfileClose}
                    />
                </div>
                <div className="flex items-center space-y-2 md:space-x-6 mb-10 flex-col md:flex-row">
                    <img
                        src={match?.user.profilePicture?.publicUrl ?? sidermenu}
                        alt="Profile"
                        className="w-1/2 md:w-1/6 md:min-w-28 rounded-xl border-4 border-spark-primary"
                    />
                    <div>
                        <h2 className="text-2xl font-bold text-center md:text-start">
                            {match?.user?.firstName} {match?.user?.lastName ?? ""}
                        </h2>
                        <p className="leading-tight text-center md:text-start">
                            {match?.user?.tagline}
                        </p>
                        <p className="text-xs mt-2 text-justify md:text-start">
                            {match?.description ?? ""}
                        </p>
                    </div>
                </div>
                <hr />
                {/* Info Section */}
                <div className="grid grid-cols-[repeat(auto-fill,minmax(160px,auto))] my-6">
                    <div className="px-3">
                        <span className="text-xs text-spark-text-gray">Score</span>
                        <div className="leading-tight flex text text-sm break-words items-center">
                            <Award05Icon className="me-1 min-w-6" />
                            {match?.score}
                        </div>
                    </div>
                    <div className="px-3">
                        <span className="text-xs text-spark-text-gray">City</span>
                        <div className="leading-tight flex text text-sm break-words items-center">
                            <City01Icon className="me-1 min-w-6" />
                            {match?.user?.city}
                        </div>
                    </div>
                    <div className="px-3">
                        <span className="text-xs font-medium text-spark-text-gray">
                            Interests
                        </span>
                        <p className="leading-tight flex text-sm break-words items-center">
                            <UserFullViewIcon className="me-1 min-w-6" />
                            {match?.interests?.join(", ")}
                        </p>
                    </div>
                    <div className="px-3">
                        <span className="text-xs text-spark-text-gray">Skills</span>
                        <p className="leading-tight flex text-sm break-words items-center">
                            <AiBrain04Icon className="me-1 min-w-6" />
                            {match?.skills?.join(", ")}
                        </p>
                    </div>
                    <div className="px-3">
                        <span className="text-xs font-medium text-spark-text-gray">
                            Looking for
                        </span>
                        <p className="leading-tight flex text-sm break-words items-center">
                            <Brain02Icon className="me-1 min-w-6" />
                            {match?.lookingFor}
                        </p>
                    </div>
                </div>
                <hr />
                {/* Event List */}
                <div className="mt-6 space-y-4">
                    {events?.map((event, i) => {
                        return <EventItem key={i} event={event} />;
                    })}
                </div>

                {/* Message Button */}
                <button
                    onClick={onMessageButtonClick}
                    className="mt-6 px-6 py-3 bg-spark-primary text-white rounded-md flex items-center space-x-1"
                >
                    <Message02Icon className="me-2" />
                    Message
                </button>
            </div>
        );
    }
);

export default MatchedUserProfile;
