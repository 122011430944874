import React from "react";
import {
  ArrowRight02Icon,
  Location01Icon,
  PinLocation01Icon,
  UserGroupIcon,
  Globe02Icon,
} from "hugeicons-react";
import sparkDefault from "../../assets/images/SiderMenu.png";

export const EventItemDetails = ({
  eventData,
  eventGuests,
  containerClass,
  ManageEvent,
  onManageClick,
}) => {
  const isoDateTimeToFormat = (date) => {
    if (!date) return "00:00";

    date = new Date(date);

    return `${date.toLocaleDateString("en-US")} ${date.toLocaleTimeString(
      "en-US"
    )}`;
  };

  const timeZone = () => {
    return new Intl.DateTimeFormat("en-US", { timeZoneName: "short" })
      .format(new Date())
      .split(" ")[1];
  };
  return (
    <div className={`${containerClass}`}>
      <p className="text-xs">
        {isoDateTimeToFormat(eventData?.startAt)} •{" "}
        {isoDateTimeToFormat(eventData?.endAt)} {timeZone()}
      </p>
      <h3 className="text-lg font-semibold">{eventData?.title}</h3>
      {eventData.type === "in_person" && (
        <>
          {eventData.address && (
            <p className="text-xs flex items-center my-1">
              <Location01Icon className="me-2" />
              {eventData.address}
            </p>
          )}
          {eventData?.latitude && (
            <p className="text-xs flex items-center my-1">
              <PinLocation01Icon className="me-2" />
              {eventData.latitude},{eventData.longitude}
            </p>
          )}
        </>
      )}

      {eventData.type === "online" && (
        <p className="text-xs flex items-center my-1">
          <Globe02Icon className="me-2" />
          Online
        </p>
      )}

      {eventGuests?.length > 0 && (
        <p className="text-xs flex items-center my-1">
          <UserGroupIcon className="me-2" />
          {eventGuests?.length} guests
        </p>
      )}
      <div className="flex sm:items-center sm:space-x-4 mt-2 sm:flex-row flex-col-reverse">
        {!ManageEvent ? (
          !eventData.userHasTicket ? (
            <button
              onClick={() => {
                onManageClick && onManageClick(eventData);
              }}
              className="px-4 py-2 bg-spark-bg-primary-light text-sm rounded-lg flex max-w-56"
            >
              Buy ticket
              <ArrowRight02Icon className="text-spark-primary ms-1" />
            </button>
          ) : (
            <></>
          )
        ) : (
          <ManageEvent
            userHasTicket={eventData.userHasTicket}
            onManageClick={onManageClick}
            event={eventData}
          />
        )}
        {eventGuests?.length > 0 && (
          <div className="flex items-center sm:mb-0 mb-3">
            <div className="inline-flex min-w-8 relative">
              {eventGuests
                ?.slice(0, eventGuests?.length > 5 ? 5 : eventGuests?.length)
                .map((guest, i) => (
                  <img
                    key={i}
                    className={`-me-[14px] z-[${6 - (i + 1)
                      }] w-8 h-8 rounded-full object-cover border`}
                    src={guest?.user?.profilePicture?.publicUrl || sparkDefault}
                    alt="Person"
                  />
                ))}
              {eventGuests?.length > 5 && (
                <div className="z-[1] -me-[5px] !w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
                  +{eventGuests.length - 5}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
