import { ArrowRight01Icon } from "hugeicons-react";
import { EventItemDetails } from "./EventItemDetails";
import sparkDefault from "../../assets/images/SiderMenu.png";

const EventItem = ({
  event,
  containerClass,
  eventContainerClass,
  showViewClick = null,
  onViewClick = null,
  isActive,
  onManageClick,
}) => {
  return (
    <div className={`${containerClass}`}>
      <div
        className={`border-s-2 ${
          event.userHasTicket
            ? "border-spark-primary"
            : "border-spark-primary-light"
        } p-4 flex justify-between md:items-center flex-col-reverse md:flex-row w-full ${
          eventContainerClass ?? ""
        }`}
      >
        <EventItemDetails
          onManageClick={onManageClick}
          eventData={event}
          eventGuests={event.guests}
        />
        <img
          src={event?.cover?.publicUrl ?? sparkDefault}
          alt="Event"
          className="w-1/6 min-w-28 object-cover rounded-lg mb-3 md:mb-0 aspect-[4/3]"
        />
      </div>
      {showViewClick && (
        <div className="flex items-center">
          <button
            onClick={onViewClick}
            className={`flex items-center justify-center h-10 w-10 rounded-lg ${
              !isActive
                ? "bg-spark-bg-primary-light text-black"
                : "bg-spark-primary text-white"
            }`}
          >
            <ArrowRight01Icon />
          </button>
        </div>
      )}
    </div>
  );
};

export default EventItem;
