import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";
import { FaArrowRightLong } from "react-icons/fa6";
import Logo from "../../assets/images/spark-logo.png";

const ResetPassword = ({ isLoggedIn }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      notificationSvc.error("Passwords do not match!");
      return;
    }

    try {
      const response = await api.post("users/reset-password", {
        token,
        password: formData.password,
      });

      if (response && (response.status === 200 || response.status === 201)) {
        notificationSvc.success("Password reset successfully.");
        navigate("/login");
      }
    } catch (error) {
      notificationSvc.error("Failed to reset password. Please try again.");
    }
  };

  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard");
  }, [isLoggedIn, navigate]);

  return (
    <>
      <div className="h-auto md:h-screen flex items-center justify-center p-6 md:p-12 bg-slate-200">
        <div className="flex flex-col md:flex-row shadow-custom bg-slate-900 rounded-3xl max-w-6xl w-full h-full p-4 md:p-2">
          {/* Left Section */}

          <div className="flex items-center md:items-end justify-center md:justify-start rounded-3xl bg-slate-200 w-full md:w-1/2 p-6 md:p-10">
            <div className="text-center md:text-left">
              <div className="mb-6">
                <img src={Logo} alt="Logo" className="mx-auto md:mx-0" />
              </div>
              <div className="mb-16">
                <h2 className="text-slate-900 text-4xl md:text-5xl lg:text-6xl !font-lexendBold mb-2">
                  Recover Password!
                </h2>
                <p className="text-slate-900 text-xl md:text-2xl lg:text-3xl !font-lexendRegular">
                  Recover your password in a few steps
                </p>
              </div>
            </div>
          </div>

          {/* Right Section */}

          <section className="w-full md:w-1/2 text-white p-6 md:p-10">
            <div className="mt-24">
              <>
                <h2 className="text-2xl md:text-3xl lg:text-4xl !font-lexendBold mb-6 text-center md:text-left">
                  Create your new password
                </h2>

                <form
                  className="mt-12"
                  onSubmit={(e) => {
                    handleResetPassword(e);
                  }}
                >
                  <div className="mb-4">
                    <label className="block text-sm md:text-base !font-lexendRegular mb-3">
                      New Password
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="w-full p-2 bg-white rounded-md text-black focus:outline-none focus:ring-2 focus:ring-spark-700"
                      value={formData.password}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          password: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm md:text-base !font-lexendRegular mb-3">
                      Repeat Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      className="w-full p-2 bg-white rounded-md text-black focus:outline-none focus:ring-2 focus:ring-spark-700"
                      value={formData.confirmPassword}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          confirmPassword: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full py-3 mt-16 flex items-center justify-center bg-slate-200 text-slate-900 rounded-md !font-lexendRegular"
                  >
                    Create Password&nbsp;&nbsp;
                    <FaArrowRightLong />
                  </button>
                </form>
              </>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
