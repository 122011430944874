import React from "react";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { RiLockPasswordLine } from "react-icons/ri";
import { PiMedalLight } from "react-icons/pi";
import { AiTwotoneDelete } from "react-icons/ai";
import Update from "./Update";
import ChangePassword from "./ChangePassword";
import Membership from "./Membership";
import { isMobile, isTablet } from "react-device-detect";
import DeleteProfile from "./DeleteProfile";

const ProfileComp = () => {
  const isMobileOrTablet = isMobile || isTablet;

  return (
    <div className="px-6 py-10">
      <div className="flex flex-col md:flex-row">
        {/* Sidebar Navigation */}
        <div
          className={`w-full bg-white md:w-1/6 ${isMobileOrTablet ? "overflow-x-auto" : ""
            }`}
        >
          <nav
            className={`flex ${isMobileOrTablet ? "flex-row" : "flex-col"} ${isMobileOrTablet ? "space-x-4" : "space-y-4"
              }  py-4 md:py-8 md:pr-8 rounded-lg`}
            aria-label="Tabs"
            role="tablist"
          >
            <button
              type="button"
              className="hs-tab-active:border-spark-700 py-2 px-4 inline-flex items-center gap-x-2 md:gap-x-4 md:border-l-4 border-transparent text-sm font-medium text-gray-500 hover:text-spark-700 focus:outline-none focus:text-spark-700 active"
              id="tab-item-1"
              aria-selected="true"
              data-hs-tab="#tab-1"
              aria-controls="tab-1"
              role="tab"
            >
              <HiOutlineUserCircle size={24} />
              Account
            </button>
            <button
              type="button"
              className="hs-tab-active:border-spark-700 py-2 px-4 inline-flex items-center gap-x-2 md:gap-x-4 md:border-l-4 border-transparent text-sm font-medium text-gray-500 hover:text-spark-700 focus:outline-none focus:text-spark-700"
              id="tab-item-2"
              aria-selected="false"
              data-hs-tab="#tab-2"
              aria-controls="tab-2"
              role="tab"
            >
              <RiLockPasswordLine size={24} />
              Change Password
            </button>
            <button
              type="button"
              className="hs-tab-active:border-spark-700 py-2 px-4 inline-flex items-center gap-x-2 md:gap-x-4 md:border-l-4 border-transparent text-sm font-medium text-gray-500 hover:text-spark-700 focus:outline-none focus:text-spark-700"
              id="tab-item-3"
              aria-selected="false"
              data-hs-tab="#tab-3"
              aria-controls="tab-3"
              role="tab"
            >
              <PiMedalLight size={24} />
              Membership
            </button>
            <button
              type="button"
              className="hs-tab-active:border-spark-700 py-2 px-4 inline-flex items-center gap-x-2 md:gap-x-4 md:border-l-4 border-transparent text-sm font-medium text-gray-500 hover:text-spark-700 focus:outline-none focus:text-spark-700"
              id="tab-item-4"
              aria-selected="false"
              data-hs-tab="#tab-4"
              aria-controls="tab-4"
              role="tab"
            >
              <AiTwotoneDelete size={24} />
              Delete Account
            </button>
          </nav>
        </div>

        {/* Tab Panels */}
        <div className="w-full md:w-5/6 mt-6 md:mt-0 md:ms-6 flex-1 bg-white rounded-lg">
          <div
            id="tab-1"
            role="tabpanel"
            aria-labelledby="tab-item-1"
            className="px-6"
          >
            <Update />
          </div>
          <div
            id="tab-2"
            className="hidden px-6"
            role="tabpanel"
            aria-labelledby="tab-item-2"
          >
            <ChangePassword />
          </div>
          <div
            id="tab-3"
            className="hidden px-6"
            role="tabpanel"
            aria-labelledby="tab-item-3"
          >
            <Membership />
          </div>
          <div
            id="tab-4"
            className="hidden px-6"
            role="tabpanel"
            aria-labelledby="tab-item-4"
          >
            <DeleteProfile />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileComp;
