import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { api } from "../../Services/api-service";
import Plan from "./Plan";
import { AnonymousIcon, Astronaut02Icon, BotIcon } from "hugeicons-react";

const Subscriptions = ({ getActivePlan, plans }) => {
  const hostName = window.location.origin;
  const checkOutStatus = {
    successUrl: `${hostName}/checkout-success`,
    failureUrl: `${hostName}/checkout-failure`,
    conflictUrl: `${hostName}/checkout-conflict`,
  };

  const planMeta = [
    null,
    { Icon: Astronaut02Icon, iconBgClass: "bg-black/[5%]" },
    { Icon: BotIcon, iconBgClass: "bg-black/[10%]" },
    { Icon: AnonymousIcon, iconBgClass: "bg-black/[15%]" },
  ];

  const public_stripe_key =
    "pk_live_51PT8FYRoudj7ROs3mCvIrXDkvc4f2QGrQrJVqKevfT53uQMvUaX0MOXedLUX6h1e4R7xNO97qTL2UV9oE4MZ46jY00ZMPeZtV3";
  const handlePayment = async (e, id) => {
    e.preventDefault();
    const stripePromise = await loadStripe(public_stripe_key);

    const response = await api.post(
      `plans/${id}/create-checkout`,
      checkOutStatus
    );

    const session = await response.data?.id;
    stripePromise.redirectToCheckout({
      sessionId: session,
    });
  };

  return (
    <div>
      <div className="p-4 overflow-y-auto w-full">
        <div className="flex-1 px-8 py-4">
          <h1 className="text-2xl font-bold">Choose Your Plan</h1>
          <p className="font-light">No contracts, no surprise fees.</p>
        </div>

        <div className="grid grid-cols-[repeat(auto-fit,minmax(320px,auto))] gap-2 rounded-lg px-3 sm:p-8 bg-white justify-center">
          {plans?.map((plan, i) => {
            return (
              <Plan
                planMeta={planMeta[i]}
                key={i}
                plan={plan}
                getActivePlan={getActivePlan}
                handlePayment={handlePayment}
                isActive={
                  plan.planId === getActivePlan().planId ||
                  (getActivePlan().planId == null && plan.price === 0)
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
