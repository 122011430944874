import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";

import { useSelector } from "react-redux";
import { Menu02Icon } from "hugeicons-react";

import defaultImage from "../../assets/images/SiderMenu.png"

const Navbar = ({ firstName, displaypicture, lastname }) => {
  const portalUrl = useSelector((state) => state.portal.url);
  const [isSideBarOpen, setSideBarOpen] = useState(false);

  useEffect(() => {
    if (portalUrl) {
      window.location.href = portalUrl;
    }
  }, [portalUrl]);

  return (
    <>
      <Sidebar isOpen={isSideBarOpen} setSideBarOpen={setSideBarOpen} />

      <header className="h-20 bg-white w-full fixed z-[1000] flex justify-between items-center px-5 shadow-lg">
        <Menu02Icon
          className="md:invisible visible"
          onClick={() => setSideBarOpen(true)}
        />
        <div className="relative flex flex-row justify-end items-center">
          <div className="flex flex-col items-end">
            <span className="text-spark-text-gray font-bold">Welcome</span>
            <span className="text-spark-text-gray">
              {firstName} {lastname}
            </span>
          </div>
          <img
            className="ms-4 w-16 h-16 border-4 border-spark-primary rounded-lg"
            src={displaypicture ?? defaultImage}
            alt="pofile"
          />
        </div>
      </header>
    </>
  );
};

export default Navbar;
